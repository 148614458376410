.concrete-calc-control.concrete-calc-v2 {
    border-radius: 0 !important;
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
    box-shadow: none !important;
    font-size: 17px !important;
    height: 45.5px;
}


.concrete-calc-submit, .concrete-calc-reset {
    border-radius: 0 !important;
    padding: 25.5px !important;
    border: none !important;
    text-transform: none !important;
    font-size: 17px !important;
}
.concrete-calc-submit, .concrete-calc-submit:hover {
    background-color: #303259 !important;
    margin-top: 0 !important;
}
.concrete-calc-submit:focus {
    box-shadow: 0 0 0 .2rem #30325960 !important;
}
.concrete-calc-reset, .concrete-calc-reset:hover {
    background-color: #24bdc1 !important;
    margin-top: 0 !important;
}
.concrete-calc-reset:focus {
    box-shadow: 0 0 0 .2rem #24bcc160 !important;
}

.concrete-calc-href {
    color: #24bdc1 !important;
}